<template>
  <v-row>
    <v-col cols="12">
      <v-row class="pa-5">
        <v-col class="pa-3 px-5 text-center d-flex justify-end">
          <v-btn class="button elevation-5 ma-2" color="primary" @click="saveAsDraft">
            save as draft
          </v-btn>
          <template v-if="draft !== null">
            <v-btn class="button elevation-5 ma-2" color="primary" @click="loadDraft">
              load draft
            </v-btn>
            <v-btn class="button elevation-5 ma-2" color="error" @click="deleteDraft">
              delete draft
            </v-btn>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-stepper
            v-model="step"
            class="pa-0 ma-auto overflow-y-auto"
            :style="{ maxHeight: '80vh' }"
          >
            <v-stepper-header class="pa-5">
              <v-stepper-step
                :complete="step > index + 1"
                :step="index + 1"
                class="pa-0 text-capitalize font-weight-bold"
                v-for="({ title }, index) in stepperData"
                :key="title"
              >
                {{ title }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                :step="index + 1"
                class="ma-0 pa-3"
                v-for="(
                  { props, on, component, stepComponent, width }, index
                ) in stepperData"
                :key="index"
              >
                <ValidationObserver v-slot="{ invalid, errors, dirty }">
                  <v-row
                    class="flex-column ma-auto elevation-5 dark_blue align-center py-5"
                    :style="{
                      width: width,
                    }"
                  >
                    <component
                      v-if="index + 1 === step"
                      v-bind="props"
                      v-on="on"
                      :is="component"
                      :step="step"
                      :errors="errors"
                      :ObserverDirty="dirty"
                      :ObserverInvalid="invalid"
                    />

                    <v-col cols="9" class="pt-5 d-flex justify-space-between">
                      <v-btn @click="backStep(stepComponent)" v-if="stepComponent != 1">
                        Back
                      </v-btn>
                      <v-btn
                        color="primary"
                        v-if="stepComponent === 3"
                        @click="saveInventory"
                      >
                        Add inventory
                      </v-btn>
                      <v-tooltip
                        bottom
                        :disabled="
                          errorBalance(stepComponent) &&
                          (stepComponent !== 4 || checkError(errors) === null)
                        "
                        v-if="stepComponent !== 6 && stepComponent !== 1"
                        color="warning"
                      >
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <v-btn
                              class="button button--outlined"
                              :disabled="
                                ((stepComponent === 2 ||
                                  stepComponent === 4 ||
                                  stepComponent === 5) &&
                                  invalid) ||
                                (stepComponent === 5 &&
                                  creditsSelectedBalance > totalAmount) ||
                                (stepComponent === 4 && !!loadingFormOrdersTable)
                              "
                              v-if="stepComponent !== 6 && stepComponent !== 1"
                              @click="updateStep(stepComponent)"
                            >
                              Continue
                            </v-btn>
                          </div>
                        </template>
                        <span class="font-weight-bold dark_blue--text">{{
                          stepComponent === 5 && creditsSelectedBalance > totalAmount
                            ? "Credit amount cannot be greater than the total amount of the order"
                            : checkError(errors)
                        }}</span>
                      </v-tooltip>
                      <v-btn color="primary" v-if="stepComponent === 6" @click="sendData">
                        Create
                      </v-btn>
                    </v-col>
                    <v-slide-y-transition>
                      <v-col
                        cols="12"
                        class="my-3 pa-5 create-order-table"
                        v-if="stepComponent === 4 && flagNewOrderTable"
                        style="position: relative; bottom: -20"
                      >
                        <div style="display: flex; flex-flow: column; max-height: 400px">
                          <div>
                            <v-btn
                              @click="setNewOrderTable"
                              class="ma-2 lighten-4 elevation-5"
                              outlined
                              small
                              style="float: right"
                            >
                              Close
                            </v-btn>
                          </div>
                          <CreateOrderTable
                            @formOrderTableSelectedItem="formOrderTableSelectedItem"
                          />
                        </div>
                      </v-col>
                    </v-slide-y-transition>
                  </v-row>
                </ValidationObserver>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FormOrders from "@/components/OM/AllOrders/FormOrders.vue";
import CustomerTable from "@/components/OM/AllOrders/CustomerTable.vue";
import NewOrdersCredit from "@/components/OM/AllOrders/NewOrdersCredit.vue";
import OrderPreview from "@/components/OM/AllOrders/OrderPreview.vue";
import FormCreateInventory from "@/components/OM/AllOrders/FormCreateInventory.vue";
import FormOrdersTable from "@/components/OM/AllOrders/FormOrdersTable.vue";
import { ValidationObserver } from "vee-validate";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { stepCustomerFields, customerFields } from "@/helpers/mocks/orders.js";
import CreateOrderTable from "@/components/InventoryQtySumm/CreateOrderTable.vue";
import { get_inventory_qty_summs } from "@/helpers/api/indexv2.js";

export default {
  components: {
    FormOrders,
    CustomerTable,
    OrderPreview,
    FormOrdersTable,
    ValidationObserver,
    CreateOrderTable,
    NewOrdersCredit,
    FormCreateInventory,
  },
  async created() {
    this.loadingFormOrdersTable = true;
    this.setCredits([]);

    if (this.inventoryQtySumms.length) {
      await Promise.all([this.getDepotsAll(), this.getDraft()]);
    } else {
      let res = await Promise.all([
        get_inventory_qty_summs(),
        this.getDepotsAll(),
        this.getDraft(),
      ]);
      this.setInventoryQtySumms(res[0].data.mergedData);
    }

    this.loadingFormOrdersTable = false;
  },
  mounted() {
    this.ordersData = this.ordersDataStore.map(
      ({ Size, Type, Cndtn, CountryAlhpa2, StateAlpha2, CityName, Depot, DepotId }) => {
        return {
          equipmentSize: Size,
          equipmentType: Type,
          equipmentCondition: Cndtn,
          depot: Depot,
          country: CountryAlhpa2,
          state: StateAlpha2,
          city: CityName,
          depotId: DepotId,
          qty: "",
          buyerPrice: "",
          temType: "Container",
        };
      }
    );
  },
  computed: {
    ...mapState({
      ordersTerm: (state) => state.moduleOrders.ordersTerm,
      ordersType: (state) => state.moduleOrders.ordersType,
      allDepots: (state) => state.moduleOrders.allDepots,
      inventoryQtySumms: (state) => state.moduleQtySumm.inventoryQtySumms,
      creditsSelected: (state) => state.moduleOrders.creditsSelected,
      credits: (state) => state.moduleOrders.credits,
      draft: (state) => state.moduleOrders.draft,
    }),
    ...mapGetters({
      // newOrderFlag: "moduleOrders/newOrderFlag",
      ordersDataStore: "moduleOrders/ordersData",
      totalAmount: "moduleOrders/totalAmount",
      creditsSelectedBalance: "moduleOrders/creditsSelectedBalance",
      getUserType: "getUserType",
    }),

    customerFields() {
      return stepCustomerFields({
        ordersTerm: this.ordersTerm,
        ordersType: this.ordersType,
        flag: this.newCustomer,
      });
    },

    stepperData() {
      let arr = [
        {
          title: "Select Customer",
          stepComponent: 1,
          width: "70%",
          component: CustomerTable,
          on: {
            showCustomer: this.showCustomer,
          },
          props: {
            searchInput: this.searchInput,
          },
        },
        {
          title: "customer data",
          stepComponent: 2,
          props: {
            data: this.customerData,
            fields: this.customerFields,
            newCustomer: this.newCustomer,
            customFields: this.customFields,
          },
          component: FormOrders,
          width: "70%",
        },

        {
          title: "order data",
          stepComponent: 4,
          component: FormOrdersTable,
          props: {
            data: this.ordersData,
            flagNewOrderTable: this.flagNewOrderTable,
            loadingFormOrdersTable: this.loadingFormOrdersTable,
          },

          width: "100%",
        },
        {
          stepComponent: 5,
          title: !this.credits.length ? "Not Credits Available" : "Credits",
          component: NewOrdersCredit,
          props: {
            custData: this.customerData,
            append: {
              totalAmount: false,
              balance: true,
            },
          },
          width: "70%",
        },
        {
          stepComponent: 6,
          title: "Preview",
          component: OrderPreview,
          props: {
            customerFields: this.customerFields,
            customFields: this.customFields,
          },
          width: "100%",
        },
      ];

      if (this.getUserType === "VB") {
        arr = [
          ...arr.slice(0, 2),
          {
            title: "add inventory",
            stepComponent: 3,
            component: FormCreateInventory,
            props: {
              data: this.inventoryData,
              errorDepot: this.errorDepot,
            },
            width: "100%",
          },
          ...arr.slice(2),
        ];
      }
      return arr;
    },
  },
  data() {
    return {
      step: 1,
      customerData: {
        transStepId: 2,
      },
      ordersData: [],
      disableForm: false,
      newCustomer: false,
      searchInput: "",
      customFields: [],
      customFieldsCopy: [],
      flagNewOrderTable: false,
      loadingFormOrdersTable: false,
      inventoryData: {},
      errorDepot: "",
    };
  },
  methods: {
    ...mapMutations({
      setCustomerData: "moduleOrders/setCustomerData",
      setOrdersData: "moduleOrders/setOrdersData",
      setNewOrderFlag: "moduleOrders/setNewOrderFlag",
      setSpinnerShow: "setSpinnerShow",
      setInventoryQtySumms: "moduleQtySumm/setInventoryQtySumms",
      setCredits: "moduleOrders/setCredits",
    }),
    ...mapActions({
      orderTypeId: "moduleOrders/orderTypeId",
      creditTermId: "moduleOrders/creditTermId",
      createNewOrder: "moduleOrders/createNewOrder",
      createOrder: "moduleOrders/createOrder",
      getDepotsAll: "moduleOrders/getDepotsAll",
      saveCustomer: "moduleOrders/saveCustomer",
      get_custom_field_by_org: "moduleOrders/get_custom_field_by_org",
      updateCustomFieldValue: "moduleOrders/updateCustomFieldValue",
      createCustomFieldByOrder: "moduleOrders/createCustomFieldByOrder",
      applyCredit: "moduleOrders/applyCredit",
      createDraftOrder: "moduleOrders/createDraftOrder",
      getDraft: "moduleOrders/getDraft",
      deleteDraftOrder: "moduleOrders/deleteDraftOrder",
      savedepotManage: "savedepotManage",
      saveTBDContainersStore: "moduleQtySumm/saveTBDContainers",
    }),
    errorBalance(step) {
      return !(step === 6 && this.creditsSelectedBalance > this.totalAmount);
    },
    checkError(errors) {
      let error = null;

      for (let value in errors) {
        if (errors[value].length > 0) {
          error = errors[value][0];

          break;
        }
      }
      if (error !== null)
        this.ordersData.map((x, i) => (error = error.replace(`_${i}`, "")));

      return error;
    },
    async showCustomer(item) {
      this.customerData["orderTypeId"] = await this.orderTypeId("Yard Buy");
      this.customerData["creditTermId"] = await this.creditTermId("Cash in Advance");

      if (item) {
        this.newCustomer = false;

        let {
          organization_address,
          organization_id,
          externalCustomerNumber,
          organization_descr,
          primary_email,
        } = item;

        this.customFields = await this.get_custom_field_by_org(organization_id);
        this.customFieldsCopy = this.customFields.map((x) => {
          return { ...x };
        });
        this.customerData["customerEntityId"] = organization_id;

        this.customerData["organization_address"] = organization_address;

        this.customerData["externalCustomerNumber"] = externalCustomerNumber;

        this.customerData["primary_email"] = primary_email.split(";")[0];
        this.customerData["customerName"] = organization_descr;
      } else {
        this.newCustomer = true;

        this.customFields = [];

        this.customFieldsCopy = [];

        this.customerFields.map((x) => {
          if (x.value !== "orderTypeId" && x.value !== "creditTermId")
            this.customerData[x.value] = "";
        });
      }
      this.step = 2;
    },
    backStep(stepComponent) {
      this.step = this.step - 1;
      if (stepComponent === 6 && !this.credits.length) this.step -= 1;
    },
    updateStep(stepComponent) {
      this.step += 1;
      if (stepComponent === 4 && !this.credits.length) this.step += 1;
      if (stepComponent === 4) this.setCustomerData(this.customerData);

      if (stepComponent >= 4) {
        this.setOrdersData(this.ordersData);
      }
    },
    deleteDraft() {
      this.deleteDraftOrder();
    },
    loadDraft() {
      this.step = this.draft.step;
      this.customerData = { ...this.draft };
      this.ordersData = this.draft.lineItems;
    },
    saveAsDraft() {
      let data = {
        ...this.customerData,
        lineItems: this.ordersData,
        step: this.step,
      };

      this.createDraftOrder({
        data,
      });
    },
    async sendData() {
      this.setCustomerData(this.customerData);
      this.setOrdersData(this.ordersData);

      let docNo;
      let orderFiltered = this.ordersData.filter((x) =>
        x.added ? (x.addInventory ? x : null) : x
      );
      let data = {
        ...this.customerData,
        lineItems: orderFiltered,
      };

      if (this.newCustomer) {
        docNo = await this.createOrder({
          order: data,
          customer: {
            organization_descr: this.customerData.customerName,
            organization_type: "C",
            primary_email: this.customerData.primary_email,
            externalCustomerNumber: this.customerData.externalCustomerNumber,
          },
        });
      } else {
        docNo = await this.createOrder({
          order: data,
          customFields: this.customFields,
        });
      }

      if (this.creditsSelected.length) {
        // apply credits
        let creditsSelected = this.creditsSelected.map((x) => {
          return {
            invoice_no: x.invoice_no,
            amount: x.balance,
          };
        });

        let dataCredits = {
          order_from: creditsSelected,
          order_to: docNo,
        };
        this.applyCredit({ data: dataCredits });
      }
      this.setSpinnerShow(true);

      setTimeout(() => {
        if (this.$route.fullPath === "/orders/" + docNo) {
          this.setNewOrderFlag(false);
          this.setOrdersData([]);
        } else {
          this.$router.push("/orders/" + docNo);
        }
        this.setSpinnerShow(false);
      }, 2000);
    },
    customFieldsChanged() {
      return new Promise((resolve) => {
        let arr = this.customFields.filter(({ value }, index) => {
          return this.customFieldsCopy[index].value !== value;
        });
        resolve(arr);
      });
    },
    setNewOrderTable() {
      this.flagNewOrderTable = !this.flagNewOrderTable;
    },

    formOrderTableSelectedItem(item) {
      let newItem = {
        equipmentSize: item.Size,
        equipmentType: item.Type,
        equipmentCondition: item.Cndtn,
        depot: item.Depot,
        country: item.CountryAlhpa2,
        state: item.StateAlpha2,
        city: item.CityName,
        depotId: item.DepotId,
        qty: "",
        buyerPrice: "",
        temType: "Container",
        newRow: false,
        ContainerNo: "",
        searchContainerNo: null,
      };

      this.$set(this.ordersData, this.ordersData.length, newItem);

      this.flagNewOrderTable = false;
    },
    async saveInventory() {
      const depot = {
        code: this.inventoryData.code,
        name: this.inventoryData.name,
        email: this.inventoryData.email,
        address: this.inventoryData.address,
      };
      let depotId;
      if (this.inventoryData.Depot === "Add New Depot") {
        const data = await this.savedepotManage({
          flagNew: true,
          data: depot,
          statusData: {
            EffectiveFrom: this.inventoryData.EffectiveFrom,
          },
        });

        if (!data) return;

        depotId = data.data.insertId;
      }

      let { Size, Cndtn, Type, Depot, Country, State, City, qtyTBD } = this.inventoryData;
      const depotValue =
        this.inventoryData.Depot === "Add New Depot" ? this.inventoryData.code : Depot;
      let data = {
        numContainers: qtyTBD,
        Size,
        Type,
        Depot: depotValue,
        Cndtn,
        Country: Country.iso2,
        State: State.name,
        City: City.name,
        Status: "IN-CY",
        added: true,
      };

      try {
        await this.saveTBDContainersStore({ data });
        this.$set(this.ordersData, this.ordersData.length, {
          equipmentSize: Size,
          equipmentType: Type,
          equipmentCondition: Cndtn,
          depot: depotValue,
          country: Country.iso2,
          state: State.name,
          city: City.name,
          depotId,
          qty: qtyTBD,
          buyerPrice: "",
          temType: "Container",
          newRow: false,
          ContainerNo: "",
          searchContainerNo: null,
          total: 0,
          added: true,
          addInventory: true,
          maxQty: qtyTBD,
        });
      } catch (error) {}

      // await get_inventory_qty_summs();
    },
  },
};
</script>

<style lang="scss" scoped>
.create-order-table {
  position: absolute;
  bottom: 0;
}
</style>
